<template>
  <h1 class="text-[40px] font-bold text-white mb-12">Contact</h1>

  <div class="flex flex-col items-center gap-10">
    <a href="https://github.com/faiqnoov" target="_blank" class="bg-white bg-opacity-5 h-fit w-fit p-4 rounded flex items-center">
      <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/github/github-original.svg" class="h-10 mr-3" />
      <span class="text-slate-200 font-medium">faiqnoov</span>
    </a>
    <a href="https://www.linkedin.com/in/muhammad-fa-iq-novriadi-096b791a2/" target="_blank" class="bg-white bg-opacity-5 h-fit w-fit p-4 rounded flex items-center">
      <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/linkedin/linkedin-original.svg" class="h-10 mr-3" />
      <span class="text-slate-200 font-medium">Muhammad Fa'iq Novriadi</span>
    </a>
    <a href="mailto:mfaiqn@gmail.com" target="_blank" class="bg-white bg-opacity-5 h-fit w-fit p-4 rounded flex items-center">
      <!-- <img src="https://icons8.com/icon/qyRpAggnV0zH/gmail" class="h-10 mr-3" /> -->
      <span class="text-slate-200 font-medium">mfaiqn@gmail.com</span>
    </a>
  </div>
  <!-- 
    linkedin
    github
    email
    
   -->
</template>