<template>
  <div class="">
    <!-- kiri -->
    <div class="lg:w-1/2">
      <h1 class="text-[40px] font-bold text-white mb-12">Overview</h1>
      <p class="text-lg text-slate-300">
        Nama saya Faiq, saya seorang software-engineer sebelum kata
        software-engineer itu ada. Saya terbiasa ngoding di Microsoft Word 2003.
      </p>
      <h2 class="text-[28px] font-bold text-white mt-12">Education</h2>
      <ul class="list-disc pl-6 text-lg text-slate-300">
        <li>MTs Amanatul Ummah Mojokerto</li>
        <li>MA Amanatul Ummah Mojokerto</li>
        <li>Politeknik Elektronika Negeri Surabaya</li>
      </ul>
      <h2 class="text-[28px] font-bold text-white mt-12">Project/Experience</h2>
      <ul class="list-disc pl-6 text-lg text-slate-300">
        <li>PENSLAB - PENS Laboratory Mapping Platform</li>
        <li>NAMEERA - Baby Spa Management System</li>
        <li>RKATALOG - Raudhah Karpet's Product Catalog</li>
      </ul>
    </div>

    <!-- kanan -->
  </div>
</template>